export const VenmoIcon = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M20.0439 2.17969C20.7029 3.32032 21 4.49518 21 5.97926C21 10.7127 17.1441 16.8618 14.0147 21.1797H6.86672L4 3.21606L10.2588 2.59335L11.7745 15.3756C13.1907 12.9578 14.9383 9.15825 14.9383 6.56776C14.9383 5.14981 14.7065 4.184 14.3443 3.38876L20.0439 2.17969Z"
			fill="#3D95CE"
		/>
	</svg>
);
