import {useConfigContext} from 'src/components/widget/hooks/useConfigContext';

export const CryptoIcon = () => {
	const {primaryColor} = useConfigContext();
	return (
		<svg
			width="24"
			height="25"
			viewBox="0 0 24 25"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7 7.74316C4.23858 7.74316 2 9.98174 2 12.7432C2 15.5046 4.23858 17.7432 7 17.7432C9.76142 17.7432 12 15.5046 12 12.7432C12 9.98174 9.76142 7.74316 7 7.74316ZM0 12.7432C0 8.87717 3.13401 5.74316 7 5.74316C10.866 5.74316 14 8.87717 14 12.7432C14 16.6092 10.866 19.7432 7 19.7432C3.13401 19.7432 0 16.6092 0 12.7432Z"
				fill={primaryColor}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11.1577 9.96518C11.5898 10.6788 11.9998 11.762 11.9998 12.7432C11.9998 13.7243 11.5898 14.8076 11.1577 15.5212C11.6896 14.7266 11.9998 13.7711 11.9998 12.7432C11.9998 11.7152 11.6896 10.7597 11.1577 9.96518ZM11.9999 7.84425C12.4759 8.32999 12.8816 8.88479 13.2007 9.49223C14.1189 8.42024 15.4804 7.74316 16.9998 7.74316C19.7613 7.74316 21.9998 9.98174 21.9998 12.7432C21.9998 15.5046 19.7613 17.7432 16.9998 17.7432C15.4804 17.7432 14.1189 17.0661 13.2007 15.9941C12.8816 16.6015 12.4759 17.1563 11.9999 17.6421C13.2696 18.9376 15.0405 19.7432 16.9998 19.7432C20.8658 19.7432 23.9998 16.6092 23.9998 12.7432C23.9998 8.87717 20.8658 5.74316 16.9998 5.74316C15.0405 5.74316 13.2696 6.5487 11.9999 7.84425Z"
				fill={primaryColor}
			/>
		</svg>
	);
};
