export const PaypalIcon = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M8.78495 22.0251L9.12912 19.7433L8.36247 19.7248H4.70166L7.24574 2.88757C7.25331 2.83613 7.27835 2.78924 7.31628 2.75545C7.3542 2.72166 7.4025 2.70322 7.45237 2.70349H13.625C15.6742 2.70349 17.0884 3.14858 17.8268 4.02709C18.1729 4.43921 18.3934 4.86988 18.5 5.34382C18.6118 5.84111 18.6138 6.43525 18.5046 7.1599L18.4967 7.21278V7.67711L18.8428 7.88179C19.1071 8.02153 19.3446 8.21056 19.5436 8.43953C19.8398 8.79189 20.0313 9.23973 20.1122 9.77068C20.1958 10.3167 20.1682 10.9665 20.0313 11.7022C19.8733 12.5484 19.618 13.2854 19.2732 13.8885C18.9689 14.4287 18.5599 14.8966 18.0722 15.2622C17.6142 15.6015 17.07 15.8591 16.4547 16.0239C15.8585 16.186 15.1787 16.2678 14.4331 16.2678H13.9527C13.6092 16.2678 13.2756 16.3969 13.0137 16.6284C12.7521 16.8623 12.5786 17.1856 12.5241 17.5405L12.4879 17.7459L11.8798 21.7675L11.8522 21.9152C11.845 21.9619 11.8324 21.9853 11.814 22.0011C11.7962 22.0163 11.7739 22.0248 11.7508 22.0251H8.78495Z"
			fill="#253B80"
		/>
		<path
			d="M19.1713 7.26611C19.1528 7.38906 19.1318 7.51476 19.1081 7.64389C18.2941 12.0062 15.5091 13.5132 11.9523 13.5132H10.1413C9.70633 13.5132 9.33979 13.8429 9.27201 14.2907L8.3448 20.4286L8.08223 22.1684C8.07177 22.2374 8.07576 22.308 8.09393 22.3752C8.1121 22.4425 8.14402 22.5048 8.18749 22.558C8.23096 22.6111 8.28494 22.6538 8.34572 22.683C8.4065 22.7123 8.47264 22.7275 8.53958 22.7275H11.7516C12.132 22.7275 12.4551 22.439 12.515 22.0475L12.5465 21.8772L13.1513 17.8714L13.1901 17.6516C13.2494 17.2587 13.5731 16.9702 13.9535 16.9702H14.4339C17.5459 16.9702 19.982 15.6514 20.694 11.8352C20.9915 10.2409 20.8375 8.90979 20.0504 7.97359C19.801 7.68398 19.5031 7.44424 19.1713 7.26611Z"
			fill="#179BD7"
		/>
		<path
			d="M18.3102 6.91227C18.0503 6.83379 17.7858 6.77254 17.5185 6.72888C16.9903 6.64415 16.4565 6.60349 15.9221 6.6073H11.084C10.8998 6.60715 10.7218 6.67576 10.5819 6.80073C10.442 6.9257 10.3496 7.09877 10.3213 7.28867L9.29206 14.0928L9.26245 14.2913C9.29451 14.0747 9.39979 13.8772 9.55924 13.7346C9.71869 13.592 9.92177 13.5136 10.1318 13.5138H11.9427C15.4996 13.5138 18.2845 12.0061 19.0985 7.64447C19.1229 7.51534 19.1433 7.38964 19.1617 7.26669C18.9469 7.14912 18.723 7.05055 18.4925 6.97203C18.432 6.95109 18.3712 6.93117 18.3102 6.91227Z"
			fill="#222D65"
		/>
		<path
			d="M10.3267 7.2882C10.3547 7.09825 10.4471 6.92509 10.5871 6.80018C10.727 6.67528 10.9053 6.60692 11.0894 6.60752H15.9275C16.5007 6.60752 17.0357 6.64667 17.5239 6.72909C17.8544 6.78329 18.1802 6.86436 18.4985 6.97156C18.7387 7.05467 18.9618 7.15289 19.1678 7.26622C19.41 5.65414 19.1658 4.55653 18.3307 3.56263C17.4101 2.46844 15.7485 2 13.6223 2H7.44964C7.01531 2 6.64482 2.3297 6.5777 2.77822L4.00664 19.7885C3.99467 19.8675 3.99922 19.9482 4.02 20.0252C4.04077 20.1022 4.07727 20.1735 4.12699 20.2344C4.1767 20.2952 4.23845 20.3441 4.30799 20.3776C4.37753 20.4112 4.4532 20.4286 4.5298 20.4287H8.34066L9.29748 14.0923L10.3267 7.2882Z"
			fill="#253B80"
		/>
	</svg>
);
