export const DafIcon = () => (
	<svg
		width="27"
		height="23"
		viewBox="0 0 37 33"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M7.056 31.772V24.45C7.056 23.554 7.028 22.784 7 22.154H8.848L8.946 23.106H8.974C9.478 22.378 10.262 22 11.256 22C12.754 22 14.098 23.302 14.098 25.486C14.098 27.978 12.516 29.154 10.99 29.154C10.164 29.154 9.52 28.818 9.212 28.37H9.184V31.772H7.056ZM9.184 25.122V26.06C9.184 26.228 9.198 26.382 9.226 26.508C9.366 27.082 9.856 27.516 10.472 27.516C11.396 27.516 11.942 26.746 11.942 25.584C11.942 24.492 11.452 23.666 10.5 23.666C9.898 23.666 9.366 24.114 9.226 24.744C9.198 24.856 9.184 24.996 9.184 25.122ZM21.0932 29H19.1752L19.0492 28.314H19.0072C18.5592 28.86 17.8592 29.154 17.0472 29.154C15.6612 29.154 14.8352 28.146 14.8352 27.054C14.8352 25.276 16.4312 24.422 18.8532 24.436V24.338C18.8532 23.974 18.6572 23.456 17.6072 23.456C16.9072 23.456 16.1652 23.694 15.7172 23.974L15.3252 22.602C15.8012 22.336 16.7392 22 17.9852 22C20.2672 22 20.9952 23.344 20.9952 24.954V27.334C20.9952 27.992 21.0232 28.622 21.0932 29ZM18.9232 26.508V25.766C17.8032 25.752 16.9352 26.018 16.9352 26.844C16.9352 27.39 17.2992 27.656 17.7752 27.656C18.3072 27.656 18.7412 27.306 18.8812 26.872C18.9092 26.76 18.9232 26.634 18.9232 26.508ZM21.6056 22.154H23.9716L24.9936 25.514C25.1056 25.92 25.2456 26.424 25.3296 26.788H25.3716C25.4696 26.424 25.5816 25.906 25.6796 25.514L26.5196 22.154H28.8016L27.2056 26.662C26.2256 29.378 25.5676 30.47 24.7976 31.156C24.0556 31.8 23.2716 32.024 22.7396 32.094L22.2916 30.288C22.5576 30.246 22.8936 30.12 23.2156 29.924C23.5376 29.756 23.8876 29.42 24.0976 29.07C24.1676 28.972 24.2096 28.86 24.2096 28.762C24.2096 28.692 24.1956 28.58 24.1116 28.412L21.6056 22.154Z"
			fill="#35BBF4"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4 0C1.79086 0 0 1.79086 0 4V15C0 17.2091 1.79086 19 4 19H32.4127C34.6218 19 36.4127 17.2091 36.4127 15V4C36.4127 1.79086 34.6218 0 32.4127 0H4ZM4.50829 4.36V14.952C5.11629 15.032 6.01229 15.112 7.27629 15.112C9.38829 15.112 11.1163 14.664 12.2523 13.72C13.2923 12.84 14.0443 11.416 14.0443 9.352C14.0443 7.448 13.3403 6.12 12.2203 5.288C11.1803 4.504 9.85229 4.136 7.80429 4.136C6.57229 4.136 5.40429 4.216 4.50829 4.36ZM6.95629 13.16V6.104C7.16429 6.056 7.51629 6.008 8.06029 6.008C10.1563 6.008 11.4683 7.192 11.4523 9.432C11.4523 12.008 10.0123 13.224 7.80429 13.208C7.50029 13.208 7.16429 13.208 6.95629 13.16ZM17.7509 12.232H20.8389L21.6709 15H24.2949L20.9509 4.216H17.7509L14.4549 15H16.9829L17.7509 12.232ZM20.4869 10.408H18.1029L18.7429 8.12C18.8484 7.74614 18.9481 7.30336 19.0455 6.8708C19.1106 6.58139 19.1748 6.29655 19.2389 6.04H19.2709C19.2969 6.14386 19.3237 6.25193 19.3512 6.36285C19.4932 6.93544 19.6541 7.58386 19.8149 8.12L20.4869 10.408ZM25.5789 4.216V15H28.0269V10.664H31.8989V8.68H28.0269V6.216H32.1709V4.216H25.5789Z"
			fill="#35BBF4"
		/>
	</svg>
);
