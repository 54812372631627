export const GoogleIcon = () => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M22 12.2296C22 11.5499 21.9438 10.8665 21.8238 10.1978H12.2013V14.0483H17.7117C17.483 15.2902 16.7483 16.3887 15.6725 17.0868V19.5853H18.9599C20.8904 17.8437 22 15.2718 22 12.2296Z"
			fill="#4285F4"
		/>
		<path
			d="M12.2013 21.9998C14.9528 21.9998 17.2731 21.1143 18.9637 19.5859L15.6762 17.0874C14.7616 17.6974 13.5808 18.0427 12.2051 18.0427C9.5436 18.0427 7.28697 16.2828 6.47728 13.9166H3.08484V16.4922C4.81667 19.8688 8.34406 21.9998 12.2013 21.9998Z"
			fill="#34A853"
		/>
		<path
			d="M6.4739 13.9165C6.04657 12.6747 6.04657 11.3299 6.4739 10.088V7.51245H3.08521C1.63826 10.3379 1.63826 13.6667 3.08521 16.4921L6.4739 13.9165Z"
			fill="#FBBC04"
		/>
		<path
			d="M12.2013 5.95801C13.6558 5.93596 15.0615 6.47239 16.1148 7.45707L19.0274 4.60224C17.1832 2.90477 14.7353 1.97152 12.2013 2.00092C8.34406 2.00092 4.81667 4.13194 3.08484 7.51218L6.47353 10.0878C7.27947 7.71794 9.53985 5.95801 12.2013 5.95801Z"
			fill="#EA4335"
		/>
	</svg>
);
