export const RepeatIcon = ({className}: {className?: string}) => (
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		stroke="currentColor"
		strokeWidth="1.5"
		strokeLinecap="round"
		strokeLinejoin="round"
		className="feather feather-repeat"
	>
		<path d="M17 1l4 4-4 4" />
		<path d="M3 11V9a4 4 0 014-4h14M7 23l-4-4 4-4" />
		<path d="M21 13v2a4 4 0 01-4 4H3" />
	</svg>
);
